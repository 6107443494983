@media (max-width: 991.98px) {
  .card-list-view {
    margin-top: 1.5rem;
  }
}
.card-list-view .col-12 {
  margin-bottom: 2.75rem;
}
.card-list-view .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  height: 100%;
}
.card-list-view .card::before {
  content: "";
  display: block;
  background-color: #ec7404;
  height: 4px;
}
.card-list-view .card > a:hover {
  text-decoration: none;
}
.card-list-view .card .card-img {
  width: 100%;
}
.card-list-view .card .card-body {
  flex: 1 1 auto;
  padding-top: 1.25rem;
}
.card-list-view .card .card-body .title,
.card-list-view .card .card-body .subtitle {
  line-height: 1.25;
  text-transform: uppercase;
}
.card-list-view .card .card-body .title {
  font-size: 1.375rem;
  font-weight: 500;
}
@media (min-width: 1200px) {
  .card-list-view .card .card-body .title {
    font-size: 1.5rem;
  }
}
.card-list-view .card .card-body .subtitle {
  font-size: 1.25rem;
  font-weight: 300;
}
@media (min-width: 1200px) {
  .card-list-view .card .card-body .subtitle {
    font-size: 1.375rem;
  }
}
.card-list-view .card .card-text {
  margin: 0.5rem 0;
}
@media (min-width: 768px) {
  .card-list-view .card.animation-border:before {
    transition: width 0.3s;
    width: 0;
  }
  .card-list-view .card.animation-border.animated:before {
    width: 100%;
  }
}

.news-layout-1 .article-wrapper:nth-child(2), .news-layout-1 .article-wrapper:nth-child(3), .news-layout-1 .article-wrapper:nth-child(4) {
  display: none;
}
@media (min-width: 768px) {
  .news-layout-1 .article-wrapper:nth-child(2) {
    display: block;
  }
}
@media (min-width: 1200px) {
  .news-layout-1 .article-wrapper:nth-child(3), .news-layout-1 .article-wrapper:nth-child(4) {
    display: block;
  }
}

@media (min-width: 1440px) {
  .card-list-view .card .card-body .title {
    font-size: 1.75rem;
  }
}
.pagination {
  display: flex;
  margin: 0;
  justify-content: center;
  padding-left: 0;
  list-style: none;
}

.page-item {
  padding: 0;
  margin-left: 0.625rem;
}
.page-item:first-child {
  margin-left: 0;
}
.page-item.previous .page-link, .page-item.next .page-link {
  text-transform: uppercase;
}
.page-item.previous .page-link::before, .page-item.next .page-link::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  border-top: 1px solid;
  border-left: 1px solid;
  top: 50%;
}
.page-item.previous .page-link {
  padding-left: 1.75rem;
}
.page-item.previous .page-link::before {
  left: 1rem;
  transform: translateY(-50%) rotate(-45deg);
}
.page-item.next .page-link {
  padding-right: 1.75rem;
}
.page-item.next .page-link::before {
  right: 1rem;
  transform: translateY(-50%) rotate(135deg);
}
.page-item.active .page-link {
  background-color: #ec7404;
  color: #fff;
  border-color: #ec7404;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  color: #4a4a4a;
  border: 1px solid;
  line-height: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  color: #fff;
  text-decoration: none;
  background-color: #4a4a4a;
  border-color: #4a4a4a;
}
.page-link:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(74, 74, 74, 0.5);
}
@media (min-width: 768px) {
  .page-link {
    font-size: 1.25rem;
  }
}

.item-detail-header {
  padding-top: 4rem;
  padding-bottom: 3rem;
}
@media (min-width: 1200px) {
  .item-detail-header {
    padding-top: 6rem;
    padding-bottom: 5rem;
  }
}

.item-detail-header-media {
  position: relative;
  padding-top: 2rem;
}
.item-detail-header-media .icon-plus {
  position: relative;
  color: #ec7404;
  stroke-width: 4px;
  font-size: 1.75rem;
  left: -0.75rem;
  bottom: -0.25rem;
}
@media (min-width: 768px) {
  .item-detail-header-media .icon-plus {
    left: -2rem;
    bottom: -0.5rem;
  }
}
@media (min-width: 1200px) {
  .item-detail-header-media {
    padding-top: 4rem;
  }
}

.item-detail-body {
  padding-top: 5rem;
  padding-bottom: 1rem;
}
@media (min-width: 1200px) {
  .item-detail-body {
    padding-top: 6.25rem;
    padding-bottom: 3.5rem;
  }
}

.news-detail-teaser {
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.35;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .news-detail-teaser {
    font-size: 1.25rem;
  }
}

.news-detail-media {
  margin-top: 4rem;
}
.news-detail-media .mfp-image {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .news-detail-media .mfp-image {
    margin-bottom: 2rem;
  }
}
@media (min-width: 1200px) {
  .news-detail-media {
    margin-top: 9rem;
  }
}

.news-detail-footer {
  padding-bottom: 4rem;
}

.job-detail-file {
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}
@media (min-width: 1200px) {
  .job-detail-file {
    padding-top: 4rem;
    padding-bottom: 3.5rem;
  }
}
.job-detail-file .btn {
  margin-top: 2rem;
}
@media (min-width: 1200px) {
  .job-detail-file .btn {
    margin-top: 3.25rem;
  }
}

.job-detail-info {
  overflow: hidden;
}
@media (min-width: 1200px) {
  .job-detail-info .c-type-text > .container > .row > .col-12 {
    flex: 0 0 75%;
    max-width: 75%;
    margin-left: 8.33333%;
  }
}