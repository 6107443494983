@import "variables";
//Bootstrap Required
@import "../../Contrib/Bootstrap/scss/functions";
@import "../../Contrib/Bootstrap/scss/variables";
@import "../../Contrib/Bootstrap/scss/mixins";


// News List
.card-list-view {
  @include media-breakpoint-down(md) {
    margin-top: 1.5rem;
  }

  .col-12 {
    margin-bottom: 2.75rem;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    height: 100%;

    &::before {
      content: '';
      display: block;
      background-color: $secondary;
      height: 4px;
    }

    > a {
      &:hover {
        text-decoration: none;
      }
    }

    .card-img {
      width: 100%;
    }

    .card-body {
      flex: 1 1 auto;
      padding-top: 1.25rem;

      .title,
      .subtitle {
        line-height: 1.25;
        text-transform: uppercase;
      }

      .title {
        font-size: 1.375rem;
        font-weight: $font-weight-medium;

        @include media-breakpoint-up(xl) {
          font-size: 1.5rem;
        }
      }

      .subtitle {
        font-size: 1.25rem;
        font-weight: $font-weight-light;


        @include media-breakpoint-up(xl) {
          font-size: 1.375rem;
        }
      }
    }

    .card-text {
      margin: 0.5rem 0;
    }
  }

  @include media-breakpoint-up(md) {
    .card {
      &.animation-border {
        &:before {
          transition: width 0.3s;
          width: 0;
        }

        &.animated {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }
}

.news-layout-1 {
  .article-wrapper {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      display: none;
    }

    @include media-breakpoint-up(md) {
      &:nth-child(2) {
        display: block;
      }
    }

    @include media-breakpoint-up(xl) {
      &:nth-child(3),
      &:nth-child(4) {
        display: block;
      }
    }
  }
}

@media (min-width: 1440px) {
  .card-list-view {
    .card {
      .card-body {
        .title {
          font-size: 1.75rem;
        }
      }
    }
  }
}


// Pagination
.pagination {
  display: flex;
  margin: 0;
  justify-content: center;
  @include list-unstyled();
}

.page-item {
  padding: 0;
  margin-left: 0.625rem;

  &:first-child {
    margin-left: 0;
  }

  &.previous,
  &.next {
    .page-link {
      text-transform: uppercase;

      &::before {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        border-top: 1px solid;
        border-left: 1px solid;
        top: 50%;
      }
    }
  }

  &.previous {
    .page-link {
      padding-left: 1.75rem;

      &::before {
        left: 1rem;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }

  &.next {
    .page-link {
      padding-right: 1.75rem;

      &::before {
        right: 1rem;
        transform: translateY(-50%) rotate(135deg);
      }

    }
  }

  &.active {
    .page-link {
      background-color: $secondary;
      color: $white;
      border-color: $secondary;
    }
  }
}

.page-link {
  position: relative;
  display: block;
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  color: $gray-main;
  border: 1px solid;
  line-height: 1;
  @include transition($btn-transition);

  &:hover {
    color: $white;
    text-decoration: none;
    background-color: $gray-main;
    border-color: $gray-main;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba($gray-main, 0.5);
  }

  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
  }
}

// News and Job Detail

.item-detail-header {
  padding-top: 4rem;
  padding-bottom: 3rem;

  @include media-breakpoint-up(xl) {
    padding-top: 6rem;
    padding-bottom: 5rem;
  }
}

.item-detail-header-media {
  position: relative;
  padding-top: 2rem;

  .icon-plus {
    position: relative;
    color: $secondary;
    stroke-width: 4px;
    font-size: 1.75rem;
    left: -0.75rem;
    bottom: -0.25rem;
  }

  @include media-breakpoint-up(md) {
    .icon-plus {
      left: -2rem;
      bottom: -0.5rem;
    }
  }

  @include media-breakpoint-up(xl) {
    padding-top: 4rem;
  }
}

.item-detail-body {
  padding-top: 5rem;
  padding-bottom: 1rem;

  @include media-breakpoint-up(xl) {
    padding-top: 6.25rem;
    padding-bottom: 3.5rem;
  }
}

// News Detail

.news-detail-teaser {
  font-size: 1.125rem;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  line-height: 1.35;
  margin-bottom: 2rem;

  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
  }
}

.news-detail-media {
  margin-top: 4rem;

  .mfp-image {
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 2rem;
    }
  }

  @include media-breakpoint-up(xl) {
    margin-top: 9rem;
  }

}

.news-detail-footer {
  padding-bottom: 4rem;
}

// Job Detail

.job-detail-file {
  padding-top: 2rem;
  padding-bottom: 1.5rem;

  @include media-breakpoint-up(xl) {
    padding-top: 4rem;
    padding-bottom: 3.5rem;
  }

  .btn {
    margin-top: 2rem;

    @include media-breakpoint-up(xl) {
      margin-top: 3.25rem;
    }
  }
}

.job-detail-info {
  overflow: hidden;

  //same width and offset as job-detail-body
  @include media-breakpoint-up(xl) {
    .c-type-text > .container > .row > .col-12 {
      flex: 0 0 75%;
      max-width: 75%;

      margin-left: 8.33333%;
    }
  }
}
